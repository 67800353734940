import React, { useEffect } from 'react';

import { Page } from '~shared/ui';

export const TermsAndConditionsPage: React.FC = () => {
  useEffect(() => {
    window.Telegram?.WebApp?.BackButton?.show();

    return () => {
      window.Telegram?.WebApp?.BackButton?.hide();
    };
  }, []);

  return (
    <Page headerContent={<h1 className="text-md">Terms and conditions</h1>}>
      <p className="text-right">Last updated on “19th of November 2024”</p>

      <h1 className="mt-2 text-center text-lg">GRAPH-DEX APP TERMS OF USE</h1>
      <p className="mt-2">
        These GraphDex App Terms of Use (hereinafter “the Terms of Use”, “the Terms” or “Agreement”) apply to GraphDex
        App located at https://t.me/graph_dex_bot?start=7397619881 in Telegram App (hereinafter “GraphDex App”, “the
        App”, “the Bot”) and operated by Graph Traiding Ltd, a company incorporated according to the laws of Republic of
        Seychelles, having Company Number 242889 with registered address at _________________________ its affiliates
        and/or partners (hereinafter “GraphDex”, “App operator”, “we”, “us” and/or “our(s)”) and GraphDex services
        specified herein (hereinafter jointly “the Services”). These Terms of Use constitute a public electronic
        agreement between You and GraphDex.
        <br />
        Before using our GraphDex App, take a moment to carefully review the Terms, as well as any other policies
        available on our App. These documents, which include the Terms, Privacy Policy, and additional guidelines,
        establish the rules and conditions for your use of the Bot.
        <br />
        If you do not accept the following Terms, please leave the App and discontinue using it. Your continued use of
        the GraphDex App implies that you acknowledge and accept all associated risks and responsibilities. By accessing
        our Bot, you are agreeing to these Terms in full, not just this section. We strongly advise that you review them
        carefully.
      </p>
      <h2 className="mt-6 text-center text-md">Contact Us:</h2>
      <p className="mt-2">
        Please direct all feedback, comments, technical support requests, and other communications related to the
        Services to us by emailing: support@graphdex.io.
      </p>

      <h2 className="mt-6 text-center text-md">1. Definitions</h2>
      <p className="mt-2">
        <b>Agreement</b> shall mean this Terms of Use which is a legally binding agreement between You and GraphDex.
        <br />
        <b>App Account</b> means a personal record of the User in GraphDex App that is linked to the User's Telegram
        account.
        <br />
        <b>App Operator</b> shall mean Graph Traiding Ltd, a company incorporated according to the laws of Republic of
        Seychelles, having Company Number 242889.
        <br />
        <b>GraphDex Platform</b> or <b>Platform</b> shall mean a platform permanently located on the internet at
        https://graphdex.io/.
        <br />
        <b>Points</b> refer to in-app points that the User accumulates by completing various tasks. Such points may
        provide Users with a Reward in the future, but it is not guaranteed.
        <br />
        <b>Prohibited Jurisdiction</b> refers to any country or region where the use of the Services provided under this
        Terms is restricted and/or deemed unlawful under applicable local, regional, or international laws. This
        includes, but is not limited to, jurisdictions where the Services are explicitly prohibited, countries listed in
        international sanctions regimes, countries included in the FATF ‘blacklist’, and any other jurisdictions subject
        to trade embargoes or restrictions imposed by international bodies or other relevant authorities. The App
        Operator reserves the right to update the list of Prohibited Jurisdictions at its sole discretion to comply with
        evolving legal and regulatory requirements.
        <br />
        <b>Referral Program</b> is a program introduced by GraphDex for the App that allows the User to receive bonuses
        when inviting others to the App.
        <br />
        <b>Rewards</b> refers to bonuses that GraphDex, at its sole discretion or on the basis of other rules which can
        be set by GraphDex, may provide to Users who have purchased In-app values and/ or take part in various App
        activities. The receipt of such Rewards is not guaranteed and will be subject to the terms set by GraphDex App.
        The receipt of such Rewards by Users will be made on an individual basis, at the sole discretion of GraphDex or
        other applicable rules.
        <br />
        <b>Telegram</b> is a messaging app that provides Users with the ability to exchange messages, send photos,
        videos, various files, audio and video messages, create channels, and more, that was uploaded to the Internet by
        Telegram Messenger LLP.
        <br />
        <b>User`s Telegram Account</b> means a personal record of the User in Telegram created using the User's phone
        number and logged in using the same number.
        <br />
        <b>Telegram Mini App</b> and/or App is an app on Telegram that allows Users to access GraphDex services.
        <br />
        <b>User</b> shall mean a private individual or a legal entity who has created the User Account in the App and
        entered in the electronic agreement with GraphDex.
        <br />
        <b>Services</b> refer to the activities and options which the Bot and the App provide for its Users. The
        Services are described in Section 3.
      </p>

      <h2 className="mt-6 text-center text-md">2. GraphDex App and Terms Acceptance</h2>
      <p className="mt-2">
        The GraphDex App is a Telegram-based application focused on gamified Point farming. Users interact with the Bot
        to earn points by completing tasks, playing a timed game, and participating in a referral system. Points
        accumulated can potentially be converted into Rewards or other Services later. The App also features sections
        for daily tasks, airdrops, and will soon include a trading terminal for decentralized exchanges (DEX). The App`s
        goal is to engage Users in earning and trading digital assets in a fun and interactive way.
        <br />
        You can access the Bot via the Telegram app, available for download on various platforms including iOS, Android,
        and desktop operating systems. The App provides a user-friendly interface that allows you to engage with the
        Bot’s features and functions, receive updates, and communicate efficiently. For the best experience, ensure that
        you have the latest version of the App installed.
        <br />
        The App Operator oversees the administration and upkeep of the App.
      </p>
      <p className="mt-2">Users accept these Terms under the following circumstances:</p>
      <ul className="list-disc pl-4">
        <li>
          Platform Access - By entering or utilizing the GraphDex App Users consent to adhere to these Terms.
          Additionally, accessing the Bot via App signifies acceptance of these Terms.
        </li>
        <li>
          Ongoing Usage - By continuing to access The GraphDex App after updates or modifications to these Terms, Users
          indicate their continued acceptance. The Bot may periodically revise these Terms, and ongoing use after such
          changes constitutes agreement to the updated Terms.
        </li>
      </ul>
      <p className="mt-2">
        If users do not accept these Terms or any subsequent changes, they must stop using the Services and Telegram Bot
        right away. Continuing to use the App despite disagreement may lead to suspension or termination access to the
        App.
      </p>
      <p className="mt-2">
        The App Operator provides you with the ability to register for an App Account using your existing account and
        log-in credentials from third-party sites and services such as Telegram Messenger (“Telegram Messenger”). To
        take advantage of this feature, we will ask you to log into or grant us permission via the Telegram Messenger.
        By adding your Telegram Messenger account to the Services and/or logging into the Services using your Telegram
        Messenger account, you allow us collect relevant information necessary to enable the Services to access that
        Telegram Messenger and your data contained within Telegram Messenger. As part of such integration, Telegram
        Messenger will provide us with access to certain information that you have provided to the Telegram Messenger,
        and we will use, store and disclose such information in accordance with our Privacy Policy. However, please
        remember that the manner in Telegram Messenger uses, stores and discloses your information is governed by the
        policies of Telegram Messenger, and App Operator shall have no liability and/or responsibility for the privacy
        practices or other actions of Telegram Messenger.
      </p>
      <p className="mt-2">
        For so long as you use your App Account, you agree to provide true, accurate, current, and complete in all
        respect information which can be accomplished by logging into your App Account and making relevant changes. You
        are responsible for maintaining the confidentiality of the password and your App Account, and are fully
        responsible for all activities that occur under your password and/or your App Account. You agree to (i)
        immediately notify us of any unauthorized use of your password and/or App Account and/or any other breach of
        security, and (ii) ensure that you exit from your App Account at the end of each session. We shall not be liable
        for any loss and/or damage arising from your failure to comply with this provisions.
      </p>

      <h2 className="mt-6 text-center text-md">3. Services</h2>
      <p className="mt-2">
        The GraphDex App grants access to its services once the User initiates interaction via the following link:
        https://t.me/graph_dex_bot?start=7397619881. Access and use of the App's features require clicking the "Start"
        button, which activates the Bot and sends a welcome message containing links to the main application, Telegram
        channel, X page and the App Operator’s website. It does not execute trades or financial transactions but offers
        engagement tools for earning and managing digital assets.
      </p>
      <p className="mt-2">
        The GraphDex App provides a range of services designed to engage users in earning and managing digital assets:
      </p>
      <ul className="list-disc pl-4">
        <li>
          Point Farming Game: Users can participate in a time-based game where they earn Points every 8 hours by
          claiming them through the App. These Points are collectible and have the potential for obtaining different
          Rewards and other perks from GraphDex, enhancing user engagement and incentivizing regular interaction with
          the App.
        </li>
        <li>
          Referral System: The App includes a 3-tier referral program, enabling Users to benefit from their network's
          activity. Users earn a percentage of Points from friends they refer to, as well as from their friends’
          referrals. Specifically, Users receive 10% of the Points earned by their direct referrals, 5% from the
          referrals of their direct referrals, and 2.5% from the referrals of those secondary referrals.
        </li>
        <li>
          Task Completion: The App offers various tasks that Users can complete to earn additional Points. These tasks
          are categorized into daily and one-time activities, such as following social media accounts and participating
          in promotional events. This system is designed to provide ongoing engagement and reward Users for completing
          specific actions.
        </li>
        <li>
          Airdrops: The "Airdrops" section within the Bot is designed to reward User activity by providing access to
          various services or benefits, which may include offerings from the App Operator, its partners, or third
          parties. The specifics of each Airdrop, including the nature of the benefits or services provided, the
          eligibility criteria, and the timing, are determined solely at the App Operator’s discretion. The App Operator
          reserves the right to modify the terms and conditions of any Airdrop, including the schedule, duration, and
          the type of rewards offered, without prior notice.
          <br />
          The subject of an Airdrop may or may not have a certain estimated monetary value. Any valuation of an
          Airdrop’s subject is performed solely by the App Operator, at its sole discretion, and is not subject to
          challenge or negotiation.
        </li>
      </ul>

      <p className="mt-2">Service Notifications</p>
      <p className="mt-2">
        By using the Bot, Users consent to receive news updates, notifications, promotional materials, reminders about
        specific Service stages, and other relevant communications related to the Bot and our Services. This includes,
        but is not limited to, information about new features, task opportunities, Point redemption events, Airdrop
        events, service updates, and any changes to the Terms of Use. Users may opt out of these notifications by
        contacting support or adjusting their settings within the Bot, if such options are available.
      </p>
      <p className="mt-2">Task Involving Donations to The Open Network (TON)</p>
      <p className="mt-2">
        Among the tasks available in the Bot, Users may be invited to make a donation in support of the development of
        The Open Network (TON). To complete such a task, the User must link and./or specify their wallet address while
        making the donation.
      </p>
      <p className="mt-2">
        Please note that the terms and conditions governing such transactions are subject to the applicable laws of the
        User's jurisdiction, as well as the policies and rules of the institution or service provider managing the
        respective wallet. The App Operator assumes no responsibility or liability for any issues, errors, or disputes
        arising from these transactions, including but not limited to incorrect wallet addresses, transaction failures,
        or delays. Users are encouraged to review and comply with the relevant terms and conditions of their wallet
        provider before proceeding with any donations.
      </p>

      <h2 className="mt-6 text-center text-md">4. User’s Eligibility Criterias</h2>
      <p className="mt-2">
        Access to and use of the GraphDex App and its Services are restricted to Users who meet the following conditions
        and affirm the following statements:
      </p>
      <ul className="list-disc pl-4">
        <li>
          You are at least 18 years old or have attained the legal age required to enter into a binding contract under
          applicable laws.
        </li>
        <li>
          You have the legal capacity to enter into a binding agreement and use the App. You are not prohibited from
          using the Services by any relevant laws or regulations.
        </li>
        <li>
          Your use of the Services complies with all applicable laws and regulations, including those related to
          anti-money laundering, anti-corruption, and counter-terrorist financing.
        </li>
        <li>You are not a politically exposed person.</li>
        <li>
          You reside in a country or territory supported by the GraphDex App and are not a citizen or resident of a
          country where the Platform does not provide Services - Prohibited Countries.
        </li>
        <li>
          You will not impersonate any individual or entity, nor will you use false identities or documents in your
          interactions with the GraphDex App.
        </li>
        <li>
          You will communicate with the App staff and contractors respectfully and professionally, avoiding defamatory,
          libelous, harmful, hateful, harassing, bullying, threatening, racially or ethnically offensive, or abusive
          language.
        </li>
        <li>
          You will not translate, reverse engineer, decompile, disassemble, modify, or create derivative works based on
          the App, in whole or in part.
        </li>
        <li>
          You will not attempt to circumvent, disable, violate, or interfere with any security-related features of the
          App.
        </li>
      </ul>
      <p>
        By using the Services, you confirm and guarantee that you meet all the eligibility requirements outlined in
        these Terms. Failure to comply with these criteria may result in the termination of your access to the GraphDex
        App and its Services.
      </p>

      <h2 className="mt-6 text-center text-md">5. Accumulation and Use of Points</h2>
      <p className="mt-2">Only registered Users can accumulate Points in the App.</p>
      <p>Users can accumulate Points in several ways:</p>
      <ul className="list-disc pl-4">
        <li>Once every 8 hours, the User can start a game on the main page of the App.</li>
        <li>Different amounts of Points are awarded for completing different tasks, which are provided in App.</li>
        <li>
          The GraphDex App also offers a referral program, whereby Users can invite others to the App and receive
          Points.
        </li>
      </ul>
      <p className="mt-2">The Points balance is displayed on the main page.</p>
      <p className="mt-2">
        Users have the option to acquire additional Points through the use of "Telegram Stars," a virtual currency
        provided by Telegram. This currency allows Users to make in-app purchases directly within the Telegram Bot,
        facilitating a smooth and seamless transaction process within the Telegram ecosystem. By purchasing points with
        Telegram Stars, Users can accelerate their progress, access additional features, or enhance their experience
        with the Bot’s services.
      </p>
      <p>
        Please note that the use of Telegram Stars is subject to Telegram’s Terms of Use and specific Telegram policies
        governing Telegram Stars transactions. Users are responsible for understanding and complying with these terms,
        as they dictate the permissible use, restrictions, and conditions associated with Telegram Stars. The App
        Operator does not assume responsibility for any issues arising from the use of Telegram Stars, and Users are
        encouraged to review Telegram’s guidelines carefully before proceeding with any purchases.
      </p>

      <p className="mt-2">
        Accumulated Points may be used in the future to gain access to exclusive benefits, Rewards or features within
        the GraphDex Platform. The availability of these benefits is subject to the App Operator’s discretion and may
        change without notice. Points are personal to the User’s account and are non-transferable, non-exchangeable, and
        cannot be redeemed for cash or any other form of fiat currency, unless specifically permitted by the GraphDex in
        the future.
      </p>
      <p>
        The accumulation of Points does not imply any ownership, security, or equity interest in the GraphDex or the
        App. Points are for entertainment and rewards purposes only. The GraphDex reserves the right to modify or
        discontinue the Points system at any time, without prior notice, and shall not be liable for any losses arising
        from such changes.
      </p>
      <p>
        The GraphDex does not guarantee the conversion of Points into any value or benefit, and Users should not rely on
        the accumulation of Points as having any monetary or financial value.
      </p>
      <p>
        The App Operator reserves the right to deduct up to 30% of a User’s accumulated Points if it identifies any
        discrepancies or instances where tasks were reported as completed but, upon verification, were found to be
        incomplete or unfulfilled. This measure is implemented to maintain the integrity and reliability of the Bot's
        reward system and to ensure fairness among all Users. Users are encouraged to review task instructions carefully
        and complete them accurately to avoid any potential deductions.
      </p>

      <h2 className="mt-6 text-center text-md">6. Termination and Suspension</h2>
      <p className="mt-2">
        This section outlines the conditions under which we may restrict, suspend, or terminate your User Account to
        ensure a safe and secure environment for all Users. Our goal is to maintain uninterrupted access to our Services
        for Users who comply with these Terms.
      </p>
      <p>When We Take Action:</p>
      <ul className="list-disc pl-4">
        <li>
          Illegal Activity: Accounts created for unlawful purposes will have transactions frozen, be deactivated, and
          reported to the relevant authorities.
        </li>
        <li>
          Unauthorized Access: If you suspect someone has accessed your Account without permission, notify our Support
          team immediately.
        </li>
        <li>
          Violations and Breaches: We reserve the right to limit, suspend, or terminate Accounts for violations of these
          Terms, illegal actions, suspicious activity, requests from legal bodies, potential breaches of security, or to
          safeguard our reputation. Specific examples include:
          <ul className="list-disc pl-4">
            <li>Money laundering, terrorist financing, corruption, or other illegal activities.</li>
            <li>Actions that negatively impact our business reputation.</li>
            <li>Court orders or legal restrictions on our cooperation with you.</li>
            <li>Failure to provide complete, accurate, or updated information.</li>
          </ul>
        </li>
      </ul>
      <p className="mt-2">
        Users will be notified by email or in Telegram in case of account restrictions, suspension, blocking, or
        cancellation.
      </p>

      <h2 className="mt-6 text-center text-md">7. Intellectual Property</h2>
      <p className="mt-2">
        All content on our GraphDex App and related Services, including but not limited to trademarks, logos, and
        intellectual property, is exclusively owned by GraphDex and protected by applicable laws.
      </p>
      <p>
        You are granted a limited, non-exclusive, non-transferable right to use our intellectual property solely for
        personal and non-commercial purposes within the scope of these Terms. Any other use, including modification,
        distribution, copying, or public display, is strictly prohibited.
      </p>
      <p>
        Unauthorized use of our intellectual property constitutes a violation of law and may result in legal action. We
        reserve the right to gather information about such violations and report them to relevant authorities.
      </p>
      <p>
        We respect the intellectual property rights of others. If you believe that any content on our Platform infringes
        your copyright or other intellectual property rights, please contact us immediately.
      </p>

      <h2 className="mt-6 text-center text-md">8. Third Party Websites</h2>
      <p className="mt-2">
        The GraphDex App may contain links to third-party websites, applications, or services that are not owned or
        controlled by the App Operator. These links are provided for your convenience and reference only.
      </p>
      <p>
        Inclusion of any link to a third-party website on the GraphDex App does not imply endorsement or approval of the
        linked website, its content, or any associated products or services by the App Operator. Users access these
        links at their own risk.
      </p>
      <p>
        The GraphDex App may display content provided by third parties. This content is the sole responsibility of the
        entity that makes it available. The App Operator does not warrant the accuracy, completeness, or usefulness of
        any third-party content.
      </p>
      <p>
        Users acknowledge and agree that they are responsible for reviewing and understanding the terms and conditions,
        privacy policies, and practices of any third-party websites they visit through links provided on the GraphDex
        App.
      </p>
      <p>
        Any transactions, communications, or other interactions between Users and third-party websites are solely
        between the User and the third party. The App Operator is not responsible for any loss or damage incurred as a
        result of such interactions.
      </p>
      <p>
        Users should exercise caution and conduct due diligence when accessing third-party websites, especially when
        sharing personal or financial information. The App Operator is not responsible for the security or privacy
        practices of third-party websites.
      </p>
      <p>
        The App Operator shall not be liable for any direct, indirect, incidental, special, or consequential damages
        resulting from Users' access to or use of third-party websites, including but not limited to damages for loss of
        profits, goodwill, use, data, or other intangible losses.
      </p>
      <p>
        If Users encounter any issues or have concerns about the content, products, or services provided by third-party
        websites linked to the GraphDex App, they are encouraged to report these issues to the App Operator.
      </p>

      <h2 className="mt-6 text-center text-md">9. Disclaimer and Limitation of liability</h2>
      <p className="mt-2">
        The Users shall be held accountable for non-performance or improper performance of their obligations under these
        Terms, in accordance with these Terms and applicable law, unless otherwise specified herein.
      </p>
      <p>
        The GraphDex App and Services are provided "AS-IS" without any guarantees, conditions, or warranties regarding
        their accuracy, quality, or fitness for a particular purpose. The GraphDex does not warrant that the App and
        Services will be error-free, reliable, or uninterrupted.
      </p>
      <p>Users acknowledge and agree that the App Operator has no control over, and bears no responsibility for:</p>
      <ul className="list-disc pl-4">
        <li>Risks associated with hardware, software, and Internet connection failures.</li>
        <li>Risks of malicious software being introduced or found in the underlying software of the App.</li>
        <li>Risks of unknown vulnerabilities or unexpected changes in the networks.</li>
      </ul>

      <p className="mt-2">
        Users release the GraphDex from all liability related to losses, damages, or claims arising from:
      </p>
      <ul className="list-disc pl-4">
        <li>Server failures or data loss.</li>
        <li>Bugs or errors in the GraphDex's software.</li>
        <li>Unauthorized third-party activities, including viruses, phishing, brute-forcing, or other attacks.</li>
      </ul>
      <p>
        The Company makes no representations about any Third-Party Content accessed through the Platform. Any terms,
        conditions, warranties, or representations associated with such content are solely between the User and the
        respective third-party organizations or individuals.
      </p>
      <p className="mt-2">
        You should not make decisions or refrain from making decisions based solely on the content in GraphDex without
        first seeking specific legal, business, or other professional advice.The application and impact of laws can vary
        greatly depending on the specific circumstances involved. Due to the evolving nature of laws, regulations, and
        the risks associated with electronic communication, there may be delays, omissions, or inaccuracies in the
        information provided within GraphDex. GraphDex MATERIALS ARE NOT FOR COMMERCIAL PURPOSES
      </p>
      <p>
        Nothing presented in GraphDex should be interpreted as promotional content or intended for commercial use.
        SECURITIES DISCLAIMER
      </p>
      <p>
        No material or information available within GraphDex should be considered or construed as a recommendation,
        endorsement, offer, invitation, or solicitation to engage in any transaction, purchase any product, or otherwise
        deal with securities, crypto assets, or other financial products. You also acknowledge that none of the
        information providers, including third parties or third-party service providers, are offering you personal
        advice regarding the nature, potential, value, or suitability of any specific security, crypto asset, portfolio,
        transaction, investment strategy, or any other matter. The information provided is not tailored to the
        investment needs of any individual.You acknowledge that investing in any security or crypto asset involves
        various risks, and discussions about any security or crypto asset published in GraphDex may not include a
        comprehensive list or description of the relevant risk factors. It is important to recognize that markets are
        constantly changing, so any information, third-party content, or other materials provided on or through GraphDex
        may be incomplete, outdated, or replaced by more current information. You assume all risks associated with
        relying on such information.
      </p>
      <p className="mt-2">
        GraphDex is not designed to offer tax, legal, insurance, or investment advice, and nothing within GraphDex
        should be interpreted as an offer to sell, a solicitation to buy, or a recommendation regarding any security or
        crypto asset by GraphDex. You bear sole responsibility for determining whether any investment, security,
        strategy, or other product or service is suitable for you, considering your investment goals and your personal
        and financial circumstances. It is advisable to consult with a legal or tax professional regarding your specific
        legal or tax situation.
      </p>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW, GraphDex DISCLAIMS ALL LIABILITY FOR ANY DAMAGES OF ANY KIND AND FURTHER
        DISCLAIMS ANY LOSSES (INCLUDING, BUT NOT LIMITED TO, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES)
        THAT ARISE FROM OR ARE IN ANY WAY CONNECTED TO YOUR USE OF OR ACCESS TO GraphDex.
      </p>
      <p>
        You assume full responsibility and act at your own risk when relying on the content provided by GraphDex, for
        which we accept no liability. It is your responsibility to ensure that any products, services, or information
        obtained through GraphDex meet your specific needs and requirements.
      </p>
      <p>
        GraphDex has no capability to prevent or mitigate attacks on blockchain networks. Regarding our app, we reserve
        the right to take any commercially reasonable actions in response to any such attack.
      </p>
      <p>
        GraphDex is not liable for ensuring the availability of the GraphDex App at all times, in all countries or
        geographic regions, or at any specific time.
      </p>
      <p>
        TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES AND REPRESENTATIONS RELATED TO THE
        SUBJECT MATTER OF THIS DISCLAIMER AND GraphDex, INCLUDING BUT NOT LIMITED TO THE ACCURACY, ADEQUACY,
        CORRECTNESS, COMPLETENESS, RELIABILITY, TIMELINESS, NON-INFRINGEMENT, TITLE, MERCHANTABILITY, OR FITNESS FOR ANY
        PARTICULAR PURPOSE OF THE DATA, INFORMATION, CONTENT, SERVICES, OR PRODUCTS AVAILABLE THROUGH THE GraphDex APP,
        OR ANYTHING ASSOCIATED WITH IT.
      </p>

      <h2 className="mt-6 text-center text-md">10. Taxes</h2>
      <p className="mt-2">
        Users are solely responsible for determining and fulfilling any tax obligations that arise from their use of the
        GraphDex App and its Services. This includes, but is not limited to, any taxes on transactions, trading gains,
        or income derived from the use of virtual assets.
      </p>
      <p>
        Users agree to comply with all applicable tax laws and regulations in their jurisdiction. The GraphDex does not
        provide tax advice and is not responsible for any tax liabilities or penalties that may result from Users' use
        of the App.
      </p>

      <h2 className="mt-6 text-center text-md">11. Term and Termination</h2>
      <p className="mt-2">
        These Terms of Use shall commence on the date the User accepts these Terms and shall continue in effect until
        terminated by either party in accordance with these Terms.
      </p>
      <p>
        Users may terminate their use of the GraphDex App and Services at any time by deactivating their User Account.
      </p>
      <p>
        The GraphDex reserves the right to terminate or suspend a User's access to the App and Services, with or without
        cause, at any time.
      </p>
      <p>Upon termination of these Terms or a User's access to the Platform:</p>
      <ul className="list-disc pl-4">
        <li>Users will no longer have access to their User Account or any associated data and content.</li>
        <li>Any outstanding financial or other obligations of the User to the Company shall remain due and payable.</li>
        <li>
          The Company may retain certain User data as required by law or for legitimate business purposes, including but
          not limited to compliance with legal obligations, dispute resolution, and fraud prevention.
        </li>
      </ul>
      <p>
        Termination of these Terms does not affect any provisions that are intended to survive termination, including
        but not limited to sections on Limitation of Liability, Taxes, and Dispute Resolution.
      </p>

      <h2 className="mt-6 text-center text-md">12. Applicable Law and Dispute Resolution</h2>
      <p className="mt-2">
        This Agreement shall be governed by, interpreted, and enforced in accordance with the laws of Republic of
        Seychelles. It shall be regarded as a contract formed under the jurisdiction of Republic of Seychelles.
      </p>
      <p>
        In the event of any disputes related to this Agreement, the parties agree to make all reasonable efforts to
        resolve such disputes through amicable negotiations.
      </p>
      <p>
        If the parties are unable to resolve the dispute through negotiation within thirty (30) calendar days, the
        dispute shall be submitted to and finally resolved under the laws of Republic of Seychelles, without regard to
        its conflict-of-law principles.
      </p>
      <p>
        The decision rendered in accordance with the laws of Republic of Seychelles shall be final and binding on both
        parties. It may be enforced in any court with appropriate jurisdiction. Should enforcement be necessary, an
        application for an enforcement order can be made to the relevant court.
      </p>

      <h2 className="mt-6 text-center text-md">13. Force Majeure</h2>
      <p className="mt-2">
        The GraphDex shall not be liable for any delays, failures in performance, or interruptions to Service caused by
        circumstances beyond its reasonable control. These circumstances may include, but are not limited to, acts of
        God, acts of war or terrorism, civil unrest, labor disputes, equipment failures, power outages, internet
        disruptions, or any other events that are unforeseeable and beyond the GraphDex's ability to control. Such
        events shall not affect the validity or enforceability of any remaining provisions of this Agreement.
      </p>

      <h2 className="mt-6 text-center text-md">14. Assignment</h2>
      <p className="mt-2">
        We reserve the right to transfer our rights and obligations under these Terms to any other party. You may not
        transfer your rights or obligations under these Terms to anyone else without our written consent.
      </p>

      <h2 className="mt-6 text-center text-md">15. Entire agreement</h2>
      <p className="mt-2">
        This Agreement constitutes the entire understanding between you and the Company regarding its subject matter and
        supersedes all prior or contemporaneous communications, representations, or agreements, whether oral or written.
        Any waiver of any provision of this Agreement must be in writing and signed by the GraphDex. Failure to enforce
        any provision of this Agreement does not constitute a waiver of that or any other provision.
      </p>

      <h2 className="mt-6 text-center text-md">16. Severability</h2>
      <p className="mt-2">
        If any part of these Terms is found to be illegal, invalid, or unenforceable, that part will be removed without
        affecting the rest of the Terms. The remaining provisions will continue to be valid and enforceable.
      </p>

      <h2 className="mt-6 text-center text-md">17. Announcements</h2>
      <p className="mt-2">
        The GraphDex may periodically post official announcements, updates, notices, and other relevant information
        (collectively referred to as "Announcements") on the GraphDex App. Users are responsible for regularly checking
        and reviewing these Announcements. The Company will not be liable for any personal losses or damages that users
        may incur due to their failure to read or respond to the Announcements in a timely manner.
      </p>

      <h2 className="mt-6 text-center text-md">18. Changes to Terms of Use </h2>
      <p className="mt-2">
        The GraphDex reserves the right to modify, update, or revise these Terms in whole or in part. This may include
        altering specific provisions, adding new terms, or removing existing ones. All such changes will be posted in
        our App.
      </p>
      <p>
        Any modifications to these Terms will become effective as of the date they are published in the App. Users are
        responsible for regularly reviewing these Terms to stay informed of any updates or changes.
      </p>
      <p>
        By continuing to use the GraphDex`s Services after any amendments to these Terms have been made, you acknowledge
        and accept the revised Terms.
      </p>

      <h1 className="mt-10 text-center text-lg">GRAPH-DEX APP PRIVACY POLICY</h1>
      <h2 className="mt-6 text-center text-md">1. General Information</h2>
      <p className="mt-2">
        This GraphDex App Privacy Policy (hereinafter the “Privacy Policy”, “Policy”) outlines how GraphDex Bot and App
        (hereinafter “GraphDex App”, “ the App” or “the Bot”) operated by Graphdex Limited, a company incorporated
        according to the laws of Hong Kong and registered under number: 76954300 with registered address: Rm 7B, One
        Capital Place, 18 Luard Road, RD WAN Chai, HK (hereinafter "Company", "GraphDex", "we", "us" or “our (s)”)
        collects, processes, uses, stores, and disclose personal information when you visit or use our App located at
        https://t.me/graph_dex_bot. It also details your rights and choices concerning this information.
      </p>
      <p>
        Please note that we reserve the right to modify this Policy at any time. The latest version of this Policy will
        always be available on this page. By continuing to use our App after any changes are made, you accept and agree
        to those changes. Therefore, we encourage you to review the Policy regularly for updates.
      </p>
      <p>
        Please note that any terms used in this Policy, which don`t mentioned in the Section 2 should be understood in
        accordance with the GraphDex App Terms of Use and Applicable Laws.
      </p>
      <p>
        GraphDex is committed to complying with the Personal Data (Privacy) Ordinance, the national legislation of Hong
        Kong and the Telegram Standard Privacy Policy providing an appropriate protection of the data you share with us.
        Thus, we constantly update our Privacy Policy.
      </p>
      <p>
        For EU residents we apply the Regulation EU 2016/679 General Data Protection Regulation (“GDPR”) and fully
        comply with it.
      </p>
      <p>
        By accessing, authorizing or using the GraphDex App, you confirm that you have read, understood, and agreed to
        all the terms and conditions outlined in this Policy. If you do not agree with any part of this Policy, please
        refrain from accessing, authorizing or using our Bot or App.
      </p>
      <p>
        As our Bot and App are powered by the Telegram platform, you share personal data with it as well. Therefore, we
        strongly recommend that you also familiarize yourself with the Telegram Privacy Policy.
      </p>

      <h2 className="mt-6 text-center text-md font-bold">
        WE RECOMMEND THAT YOU READ THIS PRIVACY POLICY THOROUGHLY AND WITHOUT ANY LIMITATIONS. IT IS CRUCIAL THAT YOU
        FULLY COMPREHEND THE CONTENTS OF THIS POLICY TO ENSURE THAT YOUR PRIVACY RIGHTS ARE RESPECTED AND PROTECTED.
      </h2>

      <h2 className="mt-6 text-center text-md">Contact Us:</h2>
      <p className="mt-2">
        Please direct all feedback, comments, technical support requests, and other communications related to the
        Services to us by emailing: support@graphdex.io.
      </p>

      <h2 className="mt-6 text-center text-md">2. Definitions</h2>
      <ul className="list-disc pl-4">
        <li>
          "Consent" constitutes an unequivocal indication of agreement by the Data Subject to the Processing or Transfer
          of their Personal Data.
        </li>
        <li>
          “Data Controller” is an entity, either an individual or an organization, that holds the responsibility for
          determining the purposes, methods, and scope of Personal Data Processing, as well as addressing any related
          matters. In this Policy Data Controller is GraphDex.
        </li>
        <li>
          “Data Processing” or “Processing” refers to any action, or series of actions, performed on data – whether by
          automated means or not. This includes collecting, storing, organizing, analyzing, sharing, and even deleting
          information. Essentially, it encompasses any way data is handled or utilized.
        </li>
        <li>“Data Subject” is the individual whose Personal Data is being processed.</li>
        <li>
          "Personal Data" refers to any information that relates to an identified or identifiable individual. This
          includes information you provide to us directly, such as your cryptocurrency wallet address, as well as
          information that is collected automatically through your use of our App and Bot, such as your Telegram
          username, user id and IP address.
        </li>
        <li>
          "Transfer of Data" refers to the act of sharing, disclosing, or transmitting Personal Data, in any format and
          through any channel, within or across borders, to individuals or organizations other than the person to whom
          the data belongs, regardless of whether the recipients are specifically identified.
        </li>
        <li>
          “Law” (or “Applicable Law”) means all applicable laws, regulations, decrees, decisions, resolutions, orders,
          etc., of the state authorities of Hong Kong having jurisdiction over GraphDex, the User or their respective
          transactions.
        </li>
      </ul>
      <p>
        The use of the above terms or other words in the singular, plural, capitalized and/or he/she or they are
        interchangeable and refer to the same thing.
      </p>

      <h2 className="mt-6 text-center text-md">3. Principles</h2>
      <p className="mt-2">In accordance with the requirements and fundamental principles of Telegram, we:</p>
      <ul className="list-disc pl-4">
        <li>Do not use your Personal Data to show you ads.</li>
        <li>
          Store only the data that is necessary for the operation of our App and the Bot and Telegram as a secure and
          multifunctional service.
        </li>
      </ul>
      <p>When Processing your Personal Data, the Company also adheres to the following principles:</p>
      <ul className="list-disc pl-4">
        <li>
          Honesty and Precision: We actively maintain the accuracy and relevance of the Personal Data we hold, ensuring
          it reflects reality and aligns with its intended purpose.
        </li>
        <li>
          Open Communication: We provide clear and accessible information about Data Processing activities, informing
          individuals of their rights and how to exercise them.
        </li>
        <li>Purpose-Driven Collection: We collect data solely for well-defined and legitimate reasons.</li>
        <li>
          Ethical Practices: We gather Personal Data through transparent and lawful methods, avoiding any form of
          deception or unfair practices.
        </li>
        <li>
          Balanced Approach: We carefully consider the necessity and relevance of the data we collect, using impact
          assessments and other measures to minimize risks and protect individuals.
        </li>
        <li>
          Strict Confidentiality: Everyone involved in handling Personal Data is obligated to maintain its secrecy,
          safeguarding it from unauthorized access or use, even after their association with us ends.
        </li>
        <li>
          Data Portability: You have the right to receive a copy of your Personal Data in a structured, commonly used
          format for your own use.
        </li>
      </ul>

      <h2 className="mt-6 text-center text-md">4. Administrative information</h2>
      <ul className="mt-2 list-disc pl-4">
        <li>Company name: Graphdex Limited;</li>
        <li>Registered code: 76954300;</li>
        <li>Address: Rm 7B, One Capital Place, 18 Luard Road, RD WAN Chai, HK;</li>
        <li>Email address: support@graphdex.io.</li>
      </ul>
      <p>
        GraphDex is the controller and responsible for User’s Personal Data. Controller is the natural or legal person,
        which, alone or jointly with others, determines the purposes and means of the processing of Personal Data.
      </p>

      <h2 className="mt-6 text-center text-md">5. Sources of Personal Information</h2>
      <p className="mt-2">
        We collect your personal information from various sources to improve your experience when using our Bot and App.
        This helps us to verify you when you first interact with the Bot and the App and to improve your subsequent
        interactions with them.
      </p>
      <p>Here's a breakdown of where this information comes from:</p>
      <ol className="mt-2 list-disc pl-4">
        <li>
          1. Information You Provide Directly
          <ul className="list-disc pl-4">
            <li>Financial Data: Cryptocurrency wallet address.</li>
            <li>Communications: Messages and requests you send directly to the Bot.</li>
          </ul>
        </li>
        <li>
          2. Information Collected Automatically
          <ul className="list-disc pl-4">
            <li>Basic Telegram data: Your username, Telegram user id and profile picture.</li>
            <li>IP address: When you use the App, we automatically receive your Internet Protocol (IP) address.</li>
            <li>
              Interaction with other services: Your subscriptions and interaction in general with other services linked
              to in the App.
            </li>
            <li>
              Data on use and diagnostics: Insights into how you use our Bot and App, including actions you take, usage
              statistics and information about your device.
            </li>
          </ul>
        </li>
        <li>
          3. Information obtained from third parties
          <ul className="list-disc pl-4">
            <li>
              Personal Data Obtained from External Sources: In addition to the information you provide directly, we may
              occasionally receive Personal Data about you from third-party sources. This could include our other
              platforms, affiliated companies or publicly accessible databases. This supplementary information might be
              combined with the data we collect through our App to enhance your experience and tailor our offerings.
            </li>
          </ul>
        </li>
      </ol>

      <h2 className="mt-6 text-center text-md">6. Purpose of Collection and Usage of information</h2>
      <p className="mt-2">
        The Company requests, collects, processes and stores only the data that is necessary for the proper functioning
        of our App.
      </p>
      <p>
        The Company will use the information received for the purposes set forth in this Policy only if we receive your
        Consent to the use of Personal Data, or in other cases determined by law. Our use of your personal information
        may include instances where it supports our legitimate business interests, including:
      </p>
      <ul className="list-disc pl-4">
        <li>Providing services to our Users.</li>
        <li>Detecting and addressing security issues regarding the provision of Services.</li>
      </ul>
      <p>
        At the same time, our interests should not override your interests or fundamental rights and freedoms that
        require the protection of Personal Data.
      </p>
      <ol className="mt-2 list-decimal pl-4">
        <li>
          To provide services and support to our Users and to improve your experience with our App, we collect Personal
          Data for the following purposes:
          <ul className="list-disc pl-4">
            <li>
              Operate and optimize: Ensure the smooth functioning of the App, its continuous improvement and elimination
              of technical problems.
            </li>
            <li>Communicate with you: Send you important notifications, App updates, and security alerts. </li>
            <li>
              Streamline inquiry routing: Direct your questions about the App or Bot to the most suitable team member
              for a timely response.
            </li>
            <li>
              Facilitate communication: Contact you directly regarding your support requests using your Telegram
              username.
            </li>
            <li>
              Thoroughly investigate concerns: Diligently research, identify, and address the problems or issues you
              refer to.
            </li>
            <li>Enhance support quality: Monitor and improve the quality of our User experience and support. </li>
            <li>
              Development of the App: Analyzing the App usage statistics, conducting quality assurance tests and
              implementing new features and adding new capabilities that meet your needs.
            </li>
          </ul>
        </li>
        <li>
          In order to timely and effectively identify and resolve security issues related to the operation of our App,
          we collect Personal Data for the following purposes:
          <ul className="list-disc pl-4">
            <li>
              Prevent and investigate fraudulent activity: Safeguard against unauthorized access and illegal use of our
              Bot or App.
            </li>
            <li>Verify identity and access: Ensure that only authorized Telegram users can access our App.</li>
            <li>Mitigate security risks: Detect and remediate spam, malware, unsafe accounts, and other threats.</li>
            <li>
              Enforce contractual obligations: Uphold our agreements with third parties and address violations of our
              Terms.
            </li>
            <li>Comply with legal requirements: Adhere to Applicable Laws and regulations to safeguard your data.</li>
          </ul>
        </li>
        <li>
          We do not monetize or use your Personal Data for services outside of our App or Bot without your express
          Consent and approval.
        </li>
        <li>
          We collect or otherwise aggregate your Personal Data only in accordance with the terms of this Policy, legal
          requirements and for the purposes of improving the functioning of our App.
        </li>
        <li>
          In accordance with applicable laws and regulations, we may use your personal information to:
          <ul className="list-disc pl-4">
            <li>Enforce our Terms of Use and other policies to maintain a safe and secure environment.</li>
            <li>Protect our rights and interests in legal proceedings or disputes.</li>
            <li>Verify your identity to prevent fraud and unauthorized access.</li>
            <li>Investigate and resolve any claims or disputes arising from your use of our App.</li>
            <li>Respond to official requests from government entities or regulatory bodies.</li>
            <li>Partner with third-party service providers to conduct sanctions checks as required by law.</li>
          </ul>
        </li>
        <li>
          To keep you informed about the latest updates, new features and offers, we may use your personal information
          to send you messages through our Bot. These messages may include news, updates, promotions, surveys, and
          invitations to various events. If you no longer wish to receive such messages, you can:
          <ul className="list-disc pl-4">
            <li>Contact us directly through our official communication channels.</li>
            <li>
              Block our Bot using the Telegram features. Please note that by blocking our Bot, you will also block your
              access to the App.
            </li>
          </ul>
        </li>
        <li>
          To protect your privacy, we may remove identifying details from your personal information, combining it with
          data from others to create anonymized or aggregated datasets. This anonymized information is used for various
          purposes without revealing your identity.
        </li>
        <p>
          Nevertheless, We collect and process your personal information in accordance with article 2, 4 of Schedule 1
          to the Personal Data (Privacy) Ordinance, 1996 - for the performance of contractual obligations between the
          Data Controller and the Data Subject.
        </p>
        <p>For EU residence - Clauses (c) of Part 1 Art. 6 of GDPR.</p>
      </ol>

      <h2 className="mt-6 text-center text-md">7. Disclose of information</h2>
      <p>
        We may share your information in the following circumstances, or for any other purpose with your explicit
        Consent, ensuring transparency and clarity in how your data is utilized and shared:
      </p>
      <ol className="mt-2 list-decimal pl-4">
        <li>
          To ensure compliance with Applicable Laws and regulations, we may share your information in the following
          circumstances:
          <ul className="list-disc pl-4">
            <li>
              Government and regulatory cooperation: Responding to inquiries or investigations from government or
              regulatory bodies.
            </li>
            <li>
              Legal obligations: Fulfilling our legal obligations when required by a subpoena, court order, or other
              legal process.
            </li>
            <li>
              Protection from harm: Disclosing information when we have a good faith belief that it is necessary to
              prevent harm to individuals.
            </li>
            <li>Reporting unlawful activity: Sharing information to report suspected illegal activities.</li>
            <li>
              Enforcement of policies: Investigating and addressing violations of our Terms of Use, agreements, or other
              applicable policies.
            </li>
          </ul>
        </li>
        <li>
          To ensure the smooth functioning of our business and to improve the App, we may share your personal
          information with our trusted partners who assist us in the areas of technology, customer support and
          marketing. These partners are obligated to protect your data and use it only for purposes that are directly
          related to the development and assistance of our App or Bot. Before sharing your data, we must always obtain
          your explicit Consent to do so.
        </li>
        <li>
          Aggregated or de-identified data, which does not contain personally identifiable information, may be disclosed
          to third parties for their own research, analysis, or other legitimate uses.
        </li>
      </ol>

      <h2 className="mt-6 text-center text-md">8. Transfer of Personal Data</h2>
      <p className="mt-2">
        We do not share your Personal Data with third parties, including our own additional platforms, services or other
        bots, unless required by law or as provided for in this Policy and agreed with you.
      </p>
      <p>
        We could share your Personal Data with our affiliated project for enhancing your Users` experience and only in
        case you agreed with this Policy.
      </p>
      <p>
        If a Transfer is necessary, we will obtain your explicit Consent in advance and ensure that our third-party
        partners adhere to data protection standards comparable to those set forth in this Policy. However, due to the
        global nature of our business, your Personal Data may be transferred to or made available to entities located in
        jurisdictions with different data protection laws.
      </p>
      <p>
        Therefore, we will implement certain legal safeguards to protect your personal information where possible. By
        using our App, you Consent to the possible Transfer of your data to jurisdictions with different privacy laws.
        However, we will in any case be required to obtain your express consent in advance and will ensure, to the
        extent possible, that your Personal Data is protected and processed in accordance with the terms of this Policy.
      </p>

      <h2 className="mt-6 text-center text-md">9. Data Subject Rights</h2>
      <p className="mt-2">
        Below is an exhaustive list of your rights as a Data Subject and the procedure for exercising them, in
        accordance with Applicable data protection laws and the requirements of the Telegram platform.
      </p>
      <p>
        We strongly advise you to carefully read them and, if you have any questions, contact us through the official
        channels listed in the “Contact Us” section.
      </p>
      <table className="border-collapse border border-white">
        <thead>
          <tr className="border border-white">
            <th className="border border-white p-1">Right</th>
            <th className="border border-white p-1">Meaning</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border border-white">
            <td className="border border-white p-1 align-top">
              <p>Access right</p>
            </td>
            <td className="border border-white p-1 align-top">
              <p>
                You have the right to request confirmation from us regarding whether your Personal Data is being
                processed. In the event of such Processing, we will provide you with the following details:
              </p>
              <ul className="list-disc pl-4">
                <li>The specific purposes for which your data is being processed</li>
                <li>The categories of Personal Data involved</li>
                <li>The recipients or types of recipients to whom your data has been or will be disclosed</li>
                <li>
                  The planned duration for which your data will be stored, or the criteria used to determine this
                  timeframe
                </li>
                <li>The source of your Personal Data if it was not obtained directly from you</li>
                <li>
                  Information on any automated decision-making processes, including profiling, and their potential
                  impact on you
                </li>
              </ul>
              <p>
                Disclosure of information on the website of the controller or in any other places which are easily
                accessible by Data Subjects is considered to be the disclosure
              </p>
            </td>
          </tr>
          <tr className="border border-white">
            <td className="border border-white p-1 align-top">The right to transfer</td>
            <td className="border border-white p-1 align-top">
              <p>
                You are entitled to receive a copy of your Personal Data in a structured, commonly used, and
                machine-readable format, allowing for interoperability and portability to another Data Controller,
                provided that:
              </p>
              <ul className="list-disc pl-4">
                <li>The data was provided directly by you.</li>
                <li>The Processing is carried out by automated means and involves a substantial amount of data.</li>
                <li>The Processing is based on your Consent or is necessary for the performance of a contract.</li>
              </ul>
              <p>Additional copies requested may be subject to a reasonable fee to cover administrative expenses.</p>
            </td>
          </tr>
          <tr className="border border-white">
            <td className="border border-white p-1 align-top">The right to have an error rectified</td>
            <td className="border border-white p-1 align-top">
              <p>
                You are entitled to request the correction of any Personal Data that is inaccurate, incomplete,
                irrelevant, outdated, or otherwise not in accordance with the facts.
              </p>
              <p>We will rectify any erroneous or incomplete data within 10 working days of receiving your request.</p>
              <p>
                However, we may deny your request if the data in question is not essential for the purposes for which it
                is being processed.
              </p>
            </td>
          </tr>
          <tr className="border border-white">
            <td className="border border-white p-1 align-top">Right to be forgotten</td>
            <td className="border border-white p-1 align-top">
              <p>
                You have the right to send us a request for the timely deletion of all Personal Data third-party
                services collected and stored in connection with them, except for the necessary data.
              </p>
              <p>
                As a data subject, you also have the right to request the timely erasure of all Personal Data collected
                and stored by us.
              </p>
              <p>Your request will be honored if one or more of the following conditions apply:</p>
              <ul className="list-disc pl-4">
                <li>
                  The Personal Data is no longer necessary for the purposes for which it was collected or processed.
                </li>
                <li>
                  You withdraw your Consent to Processing, and there is no other legal basis for us to continue
                  Processing your data.
                </li>
                <li>
                  You object to the Processing, and there are no overriding legitimate grounds for us to continue
                  Processing your data.
                </li>
                <li>The Processing of your Personal Data was unlawful.</li>
                <li>Erasure is necessary to comply with a legal obligation.</li>
              </ul>
              <p>
                Please note that certain exceptions to your right to erasure may apply, such as when the Processing is
                necessary for compliance with a legal obligation imposed on the company.
              </p>
            </td>
          </tr>
          <tr className="border border-white">
            <td className="border border-white p-1 align-top">Right to be informed</td>
            <td className="border border-white p-1 align-top">
              <p>
                You have the right to be promptly informed if there is a breach of your Personal Data, except in cases
                where the breach is unlikely to adversely affect your privacy or rights. This ensures that you are aware
                of any potential risks to your personal information and can take necessary actions to protect yourself.
              </p>
            </td>
          </tr>
          <tr className="border border-white">
            <td className="border border-white p-1 align-top">Right to opposition</td>
            <td className="border border-white p-1 align-top">
              <p>
                You have the right to object to the Processing of your Personal Data or to withdraw your Consent at any
                time and for any reason based on your particular circumstances.
              </p>
              <p>
                Upon your objection or withdrawal of Consent, we will cease Processing your Personal Data unless we can
                demonstrate compelling legitimate grounds for the Processing that override your interests, rights, and
                freedoms, or for the establishment, exercise, or defense of legal claims.
              </p>
            </td>
          </tr>
          <tr className="border border-white">
            <td className="border border-white p-1 align-top">Right to appeal</td>
            <td className="border border-white p-1 align-top">
              <p>
                If the company fails to respond to your request within the prescribed time frame (no more than 30 days)
                or fails to respect your other rights, you have the right to contact the regulatory authority.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        In order to receive information about your data in response to your request, you must verify your identity and
        provide the best way for us to contact you. We'll respond to your request, even if we don't have any information
        about you in our records.
      </p>
      <p>
        You can also ask us to provide, correct or delete your personal information at no cost to you. We will update
        our records accordingly. In some cases, we may not be able to honor your request immediately, for example, if it
        interferes with legal process or national security.
      </p>
      <p>
        Please also note that you are obligated to provide accurate and up-to-date information when sending us Data
        Transfer requests, and to cooperate with any reasonable measures that are necessary for us to comply with your
        request.
      </p>

      <h2 className="mt-6 text-center text-md">10. Data Security</h2>
      <p className="mt-2">
        We use strong security measures to protect the integrity, security and confidentiality of your personal
        information that we collect and process. In particular, we collect, process, transfer and store information in
        accordance with the law, and take the necessary preventive measures to prevent unauthorized access to,
        alteration, deletion or dissemination of your data.
      </p>
      <p>
        However, we ask you to remember that no Internet-based system can guarantee absolute security. Therefore, while
        we take strong security measures to protect your Personal Data, we cannot guarantee absolute security when using
        our Bot or App. You are also responsible for maintaining the security of your personal information when using
        our App.
      </p>
      <p>
        We take your privacy seriously and are committed to protecting your personal information from potential threats
        by implementing the following measures:
      </p>
      <ul className="list-disc pl-4">
        Verification: When you first interact with our Bot and the App, we verify your Telegram account and link the App
        to it. In the future, only your Telegram account will be able to access your savings and account in the App.
      </ul>
      <p>
        We do not assume liability for any unauthorized access or bypass of the privacy settings or security measures
        implemented within our Bot or App.
      </p>

      <h2 className="mt-6 text-center text-md">11. Dealing with third parties</h2>
      <p className="mt-2">
        Our App and Bot may contain links to external websites, mobile applications, Telegram bots or online resources
        that are not operated by us. This Privacy Policy is applicable solely to our App and does not extend to these
        third-party platforms. We encourage you to review the privacy policies and terms of use of any external websites
        or applications you access through our App, as we have no control over and assume no responsibility for their
        content, practices, or policies.
      </p>

      <h2 className="mt-6 text-center text-md">12. Policy changes</h2>
      <p className="mt-2">
        This Policy is subject to periodic revisions. The "Last Updated" date at the top of this page indicates when the
        most recent changes were made.
      </p>
      <p>
        We recommend that you periodically review this Policy to stay informed about how we handle your personal
        information. Continued use of the GraphDex App and Bot following any modifications constitutes your acceptance
        of the updated policy. If you do not consent to the changes, please cease using our App and Bot.
      </p>
    </Page>
  );
};
